import React, { useEffect, useState } from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import theme from "./Theme/theme"
import Layout from "./Routes/Layout"
import Backdrop from "./Components/BackDrop/BackDrop"
import SnackBar from "./Components/Snackbar/Snackbar"
import IsUservalid from "./Utils/IsUservalid"
import { useDispatch, useSelector } from 'react-redux'
import { setWhiteList } from './Store/context/white-list'
import setToken from './hooks/useToken'
import { baseUrl } from './baseUrl'
import { useLocation } from 'react-router-dom'
import { ENVIRONMENT } from './settings'

const App = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { code } = useSelector(state => state.whiteList)
  IsUservalid()
  const whiteList = process.env.REACT_APP_WHITE_LIST
  useEffect(() => {
    console.table({'Version': process.env.REACT_APP_VERSION})

    if (whiteList) {
      const whiteListArray = whiteList.split(',')
      const whiteListFiltered = whiteListArray.map((element, index) => whiteListArray[index] = element.trim())
      dispatch(setWhiteList( whiteListFiltered ))
    }
  }, [])

  useEffect(() => {
    if (code && [ENVIRONMENT.PRE_PRODUCTION].includes(process.env.REACT_APP_ENVIRONMENT)) setToken(`${baseUrl}/customer/bbank`, code, dispatch)
  }, [code])

  return (
    <ThemeProvider theme={theme}>
      <Backdrop />
      <SnackBar />
      <Layout />
    </ThemeProvider>
  )
}

export default App
