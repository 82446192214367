import React, { useRef, useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Link from '@mui/material/Link'
import AddIcon from '@mui/icons-material/Add'
import Input from '@mui/joy/Input'
import Button from '../../Button/Button'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { Field } from 'formik'
import ClearIcon from '@mui/icons-material/Clear'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
// styles
import { useStyles } from './ScheduleAppointmentStyle'
import InfoIcon from '../../../assets/Images/icon-blue.png'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material'

const VechiclePictures = ({ Files, formik, customerData, vehiclePictures = {} }) => {
  const classes = useStyles()
  const inputfrontPictureRef = useRef(null)
  const inputbackPictureRef = useRef(null)
  const inputinteriorPictureRef = useRef(null)
  const inputmileagePictureRef = useRef(null)
  const inputPurchaseAgrementRef = useRef(null)
  const inputMechanicCertificate = useRef(null)
  const inputAdiionalImage = useRef([])
  const [imageSelected, setImageSelected] = useState('')
  const [imageList, setimageList] = useState([
    { id: 1, name: 'Engine' },
    { id: 2, name: 'Side_1' },
    { id: 3, name: 'Side_2' },
    { id: 4, name: 'Full_View' }
  ])
  const [imageListSelected, setimageListSelected] = useState([])
  const [addVehiclePicture, setaddVehiclePicture] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [hideAddVehiclePicture, setHideAddVehiclePicture] = useState(false)

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: '#0F5CAC',
      fontSize: 14,
      maxWidth: 261,
      filter: 'drop-shadow(2px 2px 2px rgba(91, 91, 91, 0.349))',
      padding: '1rem'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: 'white',
      '&::before': {
        backgroundColor: 'white'
      }
    }
  }))

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  const handleImageList = (e) => {
    setaddVehiclePicture(false)
    setImageSelected(e.target.value)
    setimageListSelected([...imageListSelected, e.target.value])
  }

  useEffect(() => {
    const compareImageList = (curr, selec) => {
      const selectedImage = selec.map((img) => img)
      const currentImages = curr.filter(
        (currImg) => !selectedImage.includes(currImg.name)
      )
      setimageList(currentImages)
    }

    if (imageListSelected.length) {
      compareImageList(imageList, imageListSelected)
    }
  }, [imageListSelected])

  const onFrontPictureRefClick = () => {
    inputfrontPictureRef.current.click()
    formik.setFieldTouched('frontPicture')
  }

  const onBackPictureRefClick = () => {
    inputbackPictureRef.current.click()
    formik.setFieldTouched('backPicture')
  }

  const onInteriorPictureRefClick = () => {
    inputinteriorPictureRef.current.click()
    formik.setFieldTouched('interiorPicture')
  }

  const onMileagePictureRefClick = () => {
    inputmileagePictureRef.current.click()
    formik.setFieldTouched('mileagePicture')
  }

  const additionalImageRefClick = (e) => {
    inputAdiionalImage.current.click()
    console.log(imageSelected)
    if (imageSelected === 'Engine') formik.setFieldTouched('enginePicture')
    if (imageSelected === 'Side_1') formik.setFieldTouched('sideOnePicture')
    if (imageSelected === 'Side_2') formik.setFieldTouched('sideTwoPicture')
    if (imageSelected === 'Full_View')
      formik.setFieldTouched('fullViewPicturePicture')
  }

  const onMechanicCertificatePictureRefClick = () => {
    inputMechanicCertificate.current.click()
    formik.setFieldTouched('mechanicCertificateFile')
  }

  const onPurchaseAgreementPictureRefClick = () => {
    inputPurchaseAgrementRef.current.click()
    formik.setFieldTouched('purchaseAgreement')
  }

  return (
    <>
      {customerData.loanTypeId === 2 && customerData.warrantyTypeId === 2 && (
        <div variant='contained' className={classes.FullButton}>
          VEHICLE PICTURES
        </div>
      )}

      <Grid container spacing={2} className={classes.RowFields}>
        {customerData.loanTypeId === 2 &&
          customerData.warrantyTypeId === 2 &&
          (!Files?.frontPicture || (Files?.frontPicture && window.location.pathname === '/Customer/vehicle-status')) &&  (
            <Grid item xs={12} md={6}>
              <label className={classes.UploadLabelVehicle}>
                FRONT
                {window.location.pathname === '/Customer/vehicle-status'
                  ? ''
                  : '*'}
              </label>
              <Button
                Title='Upload vehicle picture'
                variant='h7'
                className={classes.UploadFiles}
                endIcon={<AttachFileIcon />}
                onClick={onFrontPictureRefClick}
              />
              <Field name='frontPicture'>
                {({ field, form, meta }) => {
                  console.log(meta)
                  return (
                    <>
                      <input
                        {...field}
                        type='File'
                        accept='application/pdf,image/*'
                        onChange={(event) => {
                          form.setFieldValue(
                            'frontPicture',
                            event.currentTarget.files[0]
                          )
                        }}
                        value=''
                        style={{ display: 'none' }}
                        ref={inputfrontPictureRef}
                        error={meta.touched && meta.error}
                      />
                      <Typography className={classes.UploadFileText}>
                      Only .jpeg, .pdf, .png, .jpg files are permitted with a maximum of 2MB
                      </Typography>
                      {meta.touched && meta.error ? (
                        <p
                          className={classes.fileText}
                          style={{ color: 'red' }}
                        >
                          {meta.error}
                        </p>
                      ) : (
                        meta?.value?.name && (
                          <p className={classes.uploadFileName}>
                            {`${String(meta.value.name).substring(0, 25)} ...`}
                            <span>
                              <ClearIcon
                                className={classes.deleteFileIcon}
                                onClick={() => {
                                  formik.setFieldValue(field.name, null)
                                }}
                              />
                            </span>
                          </p>
                        )
                      )}
                      {
                        !meta?.touched && vehiclePictures?.frontPictureName && (
                          <p className={classes.uploadFileName}>
                            {`${String(vehiclePictures?.frontPictureName).substring(0, 25)} ...`}
                            <span>
                              {/* <ClearIcon
                                className={classes.deleteFileIcon}
                                onClick={() => {
                                  formik.setFieldValue(field.name, null)
                                }}
                              /> */}
                            </span>
                          </p>
                        )
                      }
                    </>
                  )
                }}
              </Field>
            </Grid>
          )}

        {customerData.loanTypeId === 2 &&
          customerData.warrantyTypeId === 2 &&
          (!Files?.backPicture || (Files?.backPicture && window.location.pathname === '/Customer/vehicle-status')) && (
            <Grid item xs={12} md={6}>
              <label className={classes.UploadLabelVehicle}>
                BACK
                {window.location.pathname === '/Customer/vehicle-status'
                  ? ''
                  : '*'}
              </label>
              <Button
                Title='Upload vehicle picture'
                variant='h7'
                className={classes.UploadFiles}
                endIcon={<AttachFileIcon />}
                onClick={onBackPictureRefClick}
              />
              <Field name='backPicture'>
                {({ field, form, meta }) => (
                  <>
                    <input
                      {...field}
                      type='File'
                      accept='application/pdf,image/*'
                      onChange={(event) => {
                        form.setFieldValue(
                          'backPicture',
                          event.currentTarget.files[0]
                        )
                      }}
                      value=''
                      style={{ display: 'none' }}
                      ref={inputbackPictureRef}
                      error={meta.touched && meta.error}
                    />
                    <Typography className={classes.UploadFileText}>
                      Only .jpeg, .pdf, .png, .jpg files are permitted with a
                      maximum of 2MB
                    </Typography>
                    {meta.touched && meta.error ? (
                      <p className={classes.fileText} style={{ color: 'red' }}>
                        {meta.error}
                      </p>
                    ) : (
                      meta?.value?.name && (
                        <p className={classes.uploadFileName}>
                          {`${String(meta.value.name).substring(0, 25)} ...`}
                          <span>
                            <ClearIcon
                              className={classes.deleteFileIcon}
                              onClick={() => {
                                formik.setFieldValue(field.name, null)
                              }}
                            />
                          </span>
                        </p>
                      )
                    )}
                    {
                        !meta?.touched && vehiclePictures?.backPictureName && (
                          <p className={classes.uploadFileName}>
                            {`${String(vehiclePictures?.backPictureName).substring(0, 25)} ...`}
                            <span>
                              {/* <ClearIcon
                                className={classes.deleteFileIcon}
                                onClick={() => {
                                  formik.setFieldValue(field.name, null)
                                }}
                              /> */}
                            </span>
                          </p>
                        )
                      }
                  </>
                )}
              </Field>
            </Grid>
          )}
      </Grid>

      <Grid container spacing={2} className={classes.RowFields}>
        {customerData.loanTypeId === 2 &&
          customerData.warrantyTypeId === 2 &&
          (!Files?.interiorPicture || (Files?.interiorPicture && window.location.pathname === '/Customer/vehicle-status')) && (
            <Grid item xs={12} md={6}>
              <label className={classes.UploadLabelVehicle}>
                INTERIOR
                {window.location.pathname === '/Customer/vehicle-status'
                  ? ''
                  : '*'}
              </label>
              <Button
                Title='Upload vehicle picture'
                variant='h7'
                className={classes.UploadFiles}
                endIcon={<AttachFileIcon />}
                onClick={onInteriorPictureRefClick}
              />
              <Field name='interiorPicture'>
                {({ field, form, meta }) => (
                  <>
                    <input
                      {...field}
                      type='File'
                      accept='application/pdf,image/*'
                      onChange={(event) => {
                        form.setFieldValue(
                          'interiorPicture',
                          event.currentTarget.files[0]
                        )
                      }}
                      value=''
                      style={{ display: 'none' }}
                      ref={inputinteriorPictureRef}
                      error={meta.touched && meta.error}
                    />
                    <Typography className={classes.UploadFileText}>
                      Only .jpeg, .pdf, .png, .jpg files are permitted with a
                      maximum of 2MB
                    </Typography>
                    {meta.touched && meta.error ? (
                      <p className={classes.fileText} style={{ color: 'red' }}>
                        {meta.error}
                      </p>
                    ) : (
                      meta?.value?.name && (
                        <p className={classes.uploadFileName}>
                          {`${String(meta.value.name).substring(0, 25)} ...`}
                          <span>
                            <ClearIcon
                              className={classes.deleteFileIcon}
                              onClick={() => {
                                formik.setFieldValue(field.name, null)
                              }}
                            />
                          </span>
                        </p>
                      )
                    )}
                     {
                        !meta?.touched && vehiclePictures?.interiorPictureName && (
                          <p className={classes.uploadFileName}>
                            {`${String(vehiclePictures?.interiorPictureName).substring(0, 25)} ...`}
                            <span>
                              {/* <ClearIcon
                                className={classes.deleteFileIcon}
                                onClick={() => {
                                  formik.setFieldValue(field.name, null)
                                }}
                              /> */}
                            </span>
                          </p>
                        )
                      }
                  </>
                )}
              </Field>
            </Grid>
          )}

        {customerData.loanTypeId === 2 &&
          customerData.warrantyTypeId === 2 &&
          (!Files?.mileagePicture || (Files?.mileagePicture && window.location.pathname === '/Customer/vehicle-status')) && (
            <Grid item xs={12} md={6}>
              <label className={classes.UploadLabelVehicle}>
                MILEAGE
                {window.location.pathname === '/Customer/vehicle-status'
                  ? ''
                  : '*'}
              </label>
              <Button
                Title='Upload vehicle picture'
                variant='h7'
                className={classes.UploadFiles}
                endIcon={<AttachFileIcon />}
                onClick={onMileagePictureRefClick}
              />
              <Field name='mileagePicture'>
                {({ field, form, meta }) => {
                  return <>
                    <input
                      {...field}
                      type='File'
                      accept='application/pdf,image/*'
                      onChange={(event) => {
                        form.setFieldValue(
                          'mileagePicture',
                          event.currentTarget.files[0]
                        )
                      }}
                      value=''
                      style={{ display: 'none' }}
                      ref={inputmileagePictureRef}
                      error={meta.touched && meta.error}
                    />
                    <Typography className={classes.UploadFileText}>
                      Only .jpeg, .pdf, .png, .jpg files are permitted with a
                      maximum of 2MB
                    </Typography>
                    {meta.touched && ((meta.value?.size / 1024 / 1024).toFixed(4) > 2) ? (
                      <p className={classes.fileText} style={{ color: 'red' }}>
                        The file cannot exceed 2MB
                      </p>
                    ) : (
                      meta?.value?.name && (
                        <p className={classes.uploadFileName}>
                          {`${String(meta.value.name).substring(0, 25)} ...`}
                          <span>
                            <ClearIcon
                              className={classes.deleteFileIcon}
                              onClick={() => {
                                formik.setFieldValue(field.name, null)
                              }}
                            />
                          </span>
                        </p>
                      )
                    )}
                    {
                        !meta?.touched && vehiclePictures?.mileagePictureName && (
                          <p className={classes.uploadFileName}>
                            {`${String(vehiclePictures?.mileagePictureName).substring(0, 25)} ...`}
                            <span>
                              {/* <ClearIcon
                                className={classes.deleteFileIcon}
                                onClick={() => {
                                  formik.setFieldValue(field.name, null)
                                }}
                              /> */}
                            </span>
                          </p>
                        )
                      }
                  </>
                }}
              </Field>
            </Grid>
          )}
      </Grid>

      {imageListSelected.length
        ? imageListSelected.map((imgSelected, idx) => {
            return (
              <Grid
                key={idx}
                container
                spacing={2}
                className={classes.RowFields}
              >
                <Grid item xs={12} md={6}>
                  <>
                    <Input
                      // key={idx}
                      sx={{ height: '2.7rem' }}
                      type='text'
                      readOnly={true}
                      value={imgSelected}
                      placeholder=''
                    />
                  </>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name={imageListSelected[idx]}>
                    {({ field, form, meta }) => (
                      <>
                        <div style={{ display: 'flex' }}>
                          <Button
                            Title='Upload vehicle picture'
                            variant='h7'
                            className={classes.UploadFiles}
                            endIcon={<AttachFileIcon />}
                            onClick={additionalImageRefClick}
                          />
                          <input
                            {...field}
                            type='File'
                            accept='application/pdf,image/*'
                            onChange={(event) => {
                              setHideAddVehiclePicture(false)
                              form.setFieldValue(
                                `${event.currentTarget.name}`,
                                event.currentTarget.files[0]
                              )
                            }}
                            value=''
                            style={{ display: 'none' }}
                            ref={inputAdiionalImage}
                          />
                          <p className={classes.uploadFileName}>
                            <span>
                              <ClearIcon
                                className={classes.deleteFileIcon}
                                onClick={() => {
                                  setHideAddVehiclePicture(false)
                                  imageList.push({
                                    id: imageList.length,
                                    name: imgSelected
                                  })
                                  setimageListSelected((current) => {
                                    return current.filter(
                                      (img) => img !== imgSelected
                                    )
                                  })
                                }}
                              />
                            </span>
                          </p>
                        </div>

                        <Typography
                          className={classes.UploadFileText}
                          style={{ marginBottom: '4px' }}
                        >
                          Only .jpeg, .pdf, .png, .jpg files are permitted with a maximum of 2MB
                        </Typography>
                        {meta.touched && meta.error ? (
                          <span
                            className={classes.fileText}
                            style={{ color: 'red' }}
                          >
                            {meta.error} error
                          </span>
                        ) : (
                          meta?.value?.name && (
                            <span className={classes.uploadFileName}>
                              {`${String(meta.value.name).substring(
                                0,
                                25
                              )} ...`}
                              {/* <span>
                                <ClearIcon
                                  className={classes.deleteFileIcon}
                                  onClick={() => {
                                    formik.setFieldValue(field.name, null)
                                  }}
                                />
                              </span> */}
                            </span>
                          )
                        )}
                      </>
                    )}
                  </Field>
                </Grid>
              </Grid>
            )
          })
        : null}

      {customerData.loanTypeId === 2 &&
      customerData.warrantyTypeId === 2 &&
      imageList.length &&
      addVehiclePicture ? (
        <Grid container spacing={2} className={classes.RowFields}>
          <Grid item xs={12} md={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel
                style={{ top: '-15%' }}
                // sx={{ padding: '0 0.5rem', paddingTop: '-15% !important' }}
                shrink={false}
                id='select-status-label'
              >
                Select picture type
              </InputLabel>
              <Select
                sx={{
                  width: '100%',
                  // my: 2,
                  display: 'block',
                  cursor: 'default',
                  height: '2.7rem',
                  marginTop: '0',
                  '& .MuiInputBase-input.MuiOutlinedInput-input': {
                    paddingTop: '10px !important'
                  }
                }}
                id={`select-account-type`}
                value={imageListSelected}
                onChange={handleImageList}
              >
                {imageList.map((imageLabel, idx) => {
                  return (
                    <MenuItem value={imageLabel.name} key={idx}>
                      {imageLabel.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Field name='proofOfAddressImage.file'>
              {({ field, form, meta }) => (
                <>
                  <Button
                    Title='Upload vehicle picture'
                    variant='h7'
                    className={classes.UploadFiles}
                    endIcon={<AttachFileIcon />}
                    onClick={() => null}
                  />
                  <input
                    {...field}
                    type='File'
                    accept='application/pdf,image/*'
                    onChange={(event) => {
                      form.setFieldValue(
                        'proofOfAddressImage.file',
                        event.currentTarget.files[0]
                      )
                    }}
                    value=''
                    style={{ display: 'none' }}
                    // ref={inputAditionalImagesPictureRef}
                    error={meta.touched && meta.error}
                  />
                  <Typography
                    className={classes.UploadFileText}
                    style={{ marginBottom: '4px' }}
                  >
                    Only .jpeg, .pdf, .png, .jpg files are permitted with a
                    maximum of 2MB
                  </Typography>
                  {meta.touched && meta.error ? (
                    <span className={classes.fileText} style={{ color: 'red' }}>
                      {meta.error}
                    </span>
                  ) : (
                    meta?.value?.name && (
                      <span className={classes.uploadFileName}>
                        {`${String(meta.value.name).substring(0, 25)} ...`}
                        <span>
                          <ClearIcon
                            className={classes.deleteFileIcon}
                            onClick={() => {
                              formik.setFieldValue(field.name, null)
                            }}
                          />
                        </span>
                      </span>
                    )
                  )}
                </>
              )}
            </Field>
          </Grid>
        </Grid>
      ) : null}

      {window.location.pathname === '/Customer/vehicle-status' ? (
        <Grid container spacing={2} className={classes.RowFields}>
          <Grid item>
            {customerData.loanTypeId === 2 &&
            customerData.warrantyTypeId === 2 &&
            imageList.length && !hideAddVehiclePicture ? (
              <Link
                component='button'
                underline='none'
                className={classes.Link}
                onClick={() => {
                  setHideAddVehiclePicture(true)
                  setaddVehiclePicture(true)
                }}
              >
                Add another vehicle picture{' '}
                <AddIcon sx={{ width: '16px', height: '16px' }} />
              </Link>
            ) : null}
          </Grid>
        </Grid>
      ) : null}

      <Grid container spacing={2} className={classes.RowFields}>
        {customerData.loanTypeId === 2 &&
          customerData.warrantyTypeId === 2 &&
          window.location.pathname === '/Customer/vehicle-status' && (
            <Grid item xs={12} md={6}>
              <label className={classes.UploadLabelVehicle}>
                MECHANIC CERTIFICATE
              </label>
              <Button
                Title='Upload mechanic certificate'
                variant='h7'
                className={classes.UploadFiles}
                endIcon={<AttachFileIcon />}
                onClick={onMechanicCertificatePictureRefClick}
              />
              <Field name='mechanicCertificateFile'>
                {({ field, form, meta }) => (
                  <>
                    <input
                      {...field}
                      type='File'
                      accept='application/pdf,image/*'
                      onChange={(event) => {
                        form.setFieldValue(
                          'mechanicCertificateFile',
                          event.currentTarget.files[0]
                        )
                      }}
                      value=''
                      style={{ display: 'none' }}
                      ref={inputMechanicCertificate}
                      error={meta.touched && meta.error}
                    />
                    <Typography className={classes.UploadFileText}>
                      Only .jpeg, .pdf, .png, .jpg files are permitted with a
                      maximum of 2MB
                    </Typography>
                    {meta.touched && meta.error ? (
                      <p className={classes.fileText} style={{ color: 'red' }}>
                        {meta.error}
                      </p>
                    ) : (
                      meta?.value?.name && (
                        <p className={classes.uploadFileName}>
                          {`${String(meta.value.name).substring(0, 25)} ...`}
                          <span>
                            <ClearIcon
                              className={classes.deleteFileIcon}
                              onClick={() => {
                                formik.setFieldValue(field.name, null)
                              }}
                            />
                          </span>
                        </p>
                      )
                    )}
                    {
                        !meta?.touched && formik?.values?.mechanicCertificateFileName && (
                          <p className={classes.uploadFileName}>
                            {`${String(formik?.values?.mechanicCertificateFileName).substring(0, 25)} ...`}
                            <span>
                            </span>
                          </p>
                        )
                      }
                  </>
                )}
              </Field>
            </Grid>
          )}

        {(!Files.purchaseAgreement || (Files?.purchaseAgreement && window.location.pathname === '/Customer/vehicle-status')) && (
          <Grid item xs={12} md={6}>
            <label
              className={classes.UploadLabelVehicle}
              style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
            >
              PURCHASE AGREEMENT
              {window.location.pathname === '/Customer/vehicle-status'
                ? ''
                : '*'}
                <span onClick={toggleTooltip}>
                  <LightTooltip title={`A purchase agreement is a written agreement between the buyer and the seller that outlines the terms and conditions of the purchase.`}
                  arrow
                  // placement='right'
                  open={showTooltip}>
                  <img
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onClick={(e) => {
                        e.stopPropagation()
                        toggleTooltip()
                      }}
                      src={InfoIcon}
                      alt=''
                    />
              </LightTooltip>
                </span>
            </label>
            <Button
              Title='Upload puchase agreement'
              variant='h7'
              className={classes.UploadFiles}
              endIcon={<AttachFileIcon />}
              onClick={onPurchaseAgreementPictureRefClick}
            />
            <Field name='purchaseAgreement'>
              {({ field, form, meta }) => {
                return <>
                  <input
                    {...field}
                    type='File'
                    accept='application/pdf,image/*'
                    onChange={(event) => {
                      form.setFieldValue(
                        'purchaseAgreement',
                        event.currentTarget.files[0]
                      )
                    }}
                    value=''
                    style={{ display: 'none' }}
                    ref={inputPurchaseAgrementRef}
                    error={meta.touched && meta.error}
                  />
                  <Typography className={classes.UploadFileText}>
                    Only .jpeg, .pdf, .png, .jpg files are permitted with a
                    maximum of 2MB
                  </Typography>
                  {meta.touched && meta.error ? (
                    <p className={classes.fileText} style={{ color: 'red' }}>
                      {meta.error}
                    </p>
                  ) : (
                    meta?.value?.name && (
                      <p className={classes.uploadFileName}>
                        {`${String(meta.value.name).substring(0, 25)} ...`}
                        <span>
                          <ClearIcon
                            className={classes.deleteFileIcon}
                            onClick={() => {
                              formik.setFieldValue(field.name, null)
                            }}
                          />
                        </span>
                      </p>
                    )
                  )}
                  {
                        !meta?.touched && formik?.values?.purchaseAgreementFileName && (
                          <p className={classes.uploadFileName}>
                            {`${String(formik?.values?.purchaseAgreementFileName).substring(0, 25)} ...`}
                            <span>
                            </span>
                          </p>
                        )
                      }
                </>
              }}
            </Field>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default VechiclePictures
